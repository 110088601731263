<template>
  <input type="checkbox" id="my-modal" class="modal-toggle" checked/>
  <div v-show="uiStore.imageFromInternetModal" class="modal">
    <div class="modal-box  w-11/12 max-w-5xl bg-gray-300">
      <h3 class="font-bold text-lg">Enter URL to image</h3>
      <div class="p-3 text-center bg-gray-400">
        <input type="text" class="input w-11/12 max-w-3xl" v-model="uiStore.fetchedImageUrl" placeholder="http://url.to.image" id="imageUrlInputElement">
      </div>
      <!-- <div class="divider"></div> -->

      <div class="flex flex-no-wrap justify-around max-w p-3">
        <button class="btn btn-secondary" @click="uiStore.toggleImageFromInternetModalModal">Close</button>
        <button class="btn btn-tertiary" @click="uiStore.fetchedImageUrl = '';uiStore.fetchedImageError = ''">Reset URL</button>
        <button class="btn btn-primary" @click.prevent="$emit('fetchUrl')"  :disabled="uiStore.fetchedImageUrl.trim() === ''">Fetch</button>
      </div>
      
      <div v-show="uiStore.awaitingFetchImage">Retrieving {{uiStore.fetchedImageUrl}}  : <div style="display:inline-block" class="spinner-border text-success justify-content-between" role="status"> </div></div>

      <div v-show="uiStore.fetchedImageError">
          Error: {{uiStore.fetchedImageError}}
          <br/>
          (<b>Tip:</b> <i>There are many reasons why we may be unable to fetch the image for you. If you have access to the image, download it locally and upload it to the Online Image Optimizer. Please be respectful of other people's copyright.</i>)
      </div>
    </div>
  </div>
</template>

<script>
import { useUiElements } from "./../store/uiElements"

export default {
  
  emits: ['fetchUrl'],
  setup() {

    const uiStore = useUiElements()

    return { uiStore }
  },
}
</script>
