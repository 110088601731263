// import { ref } from 'vue'
import { storeToRefs } from "pinia"
import { useUiElements } from "./../store/uiElements"

export default function () {


	const uiStore = useUiElements()
	const {files} = storeToRefs(uiStore)

	function addFiles(newFiles) {

		// RESET Pinia State 
		// console.log("addFiles: New file is added. Resetting State")
        // console.log("should trigger SECOND ")
		// console.log("%o", newFiles[0])
		uiStore.$reset()
		const previewer = document.getElementById("fetchedUrlPreview")
		removeAllChildNodes(previewer)


		// modified by Luca to only allow upload of a single file.
		let filesToUpload = [...newFiles].splice(0,1)

		// check that file has an image MIME type
		if (!filesToUpload[0].type.includes('image')) {
			alert("Sorry. Doesn't look like a valid IMAGE file.")
			return;
		}

		let newUploadableFiles = filesToUpload.map((file) => new UploadableFile(file)).filter((file) => !fileExists(file.id))
		files.value = files.value.concat(newUploadableFiles)
		uiStore.showAddSetting = true
		uiStore.showFetchedImagePreviewArea = false
	}

	function fileExists(otherId) {
		return files.value.some(({ id }) => id === otherId)
	}

	function removeFile(file) {

		// if it's the last file, let's reset UI
		
		console.log("Last file is removed. Resetting State")
		if (files.value.length < 2) {
			uiStore.$reset()	
			const previewer = document.getElementById("fetchedUrlPreview")
			removeAllChildNodes(previewer)

		}

		const index = files.value.indexOf(file)

		if (index > -1) files.value.splice(index, 1)
	}

	
	function removeAllChildNodes(parent) {
		while (parent.firstChild) {
			parent.removeChild(parent.firstChild);
		}
	}


	// return { files, addFiles, removeFile }
	return { files, addFiles, removeFile, removeAllChildNodes }
}

class UploadableFile {
	constructor(file) {
		this.file = file
		this.name = file.name
		this.size = file.size
		this.width = 0
		this.height = 0
		this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`
		this.url = URL.createObjectURL(file)
		this.status = null
	}
}
