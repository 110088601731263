// import { ref } from 'vue'
import { storeToRefs } from "pinia"
import { useUiElements } from "./../store/uiElements"
import * as JSZip from 'jszip'


const htmlTemplatePart1 = `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width,initial-scale=1.0">
    <title>Image Optimization Powerd by ImageEngine.io</title>
    <style>
    html {
        box-sizing: border-box;
        font-size: 16px;
      }
      
      *, *:before, *:after {
        box-sizing: inherit;
      }
      
      body, h1, h2, h3, h4, h5, h6, p {
        margin: 0;
        padding: 0;
        font-weight: normal;
      }
      
      img {
        max-width: 100%;
        height: auto;
      }
    </style>
  </head>
  <body>
  `


const htmlTemplatePart2 = `
<br/>
<div style="text-align:center">Image Optimized with <a href="https://www.onlineimageoptimizer.com" target="_blank">Online Image
Optimizer</a> (Powered by <a href="https://www.ImageEngine.io" target="_blank">ImageEngine by ScientiaMobile</a>)</div>
  </body>
</html>`

export default function () {

    
	const uiStore = useUiElements()
	const {imageVersions, /* pictureElementText */} = storeToRefs(uiStore) // needed to build downloadable zip file

    
    // Trigger "Save as" dialog to let user save single image
    function downloadSingleImage(url, name) {
        // console.log("Downlading image name: "+ name)
        
        fetch(url)
        .then(resp => resp.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.style.display = 'none';
            link.href = url;
            // the filename you want
            link.download = name;
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
        })
        .catch(() => alert('Something went wrong. Image cannot be downloaded, sorry!'));
    }


    // build zip file blob and associate to our big button link
    // ref: https://codingshiksha.com/angular/angular-13-jszip-example-to-download-compress-multiple-files-into-a-zip-file-in-browser-using-typescript/
    async function downloadAsZip() {

        const zip = new JSZip()

        for (const img of imageVersions.value) {
    
            // let blob = await fetch(img.blob).then(r => r.blob() )
            const resp = await fetch(img.blob)
            const blob = await resp.blob() 
            zip.file(img.filename, blob, {binary: true})
        }


        zip.file("picture_elem_tags.txt", document.getElementById("pictureElementText").innerText)

        // adding a page that shows the effect in a standalone page
        zip.file("picture_elem_page.html", htmlTemplatePart1+document.getElementById("pictureElementText").innerText+htmlTemplatePart2)

        // console.dir(zip)
        // console.log("Done collecting file, let's build link to Blob")
        
        zip.generateAsync({type: 'blob'}).then((content) => {
            console.dir(content)
            const objectUrl = URL.createObjectURL(content)
            const link = document.createElement('a')

            link.download = 'Image_Optimization_poweredBy_ImageEngine.zip'
            link.href = objectUrl
            link.click()
        })
    }



    // turn bytes in Kb and Mbs
    // https://stackoverflow.com/questions/1310378/determining-image-file-size-dimensions-via-javascript
    function formatBytes(bytes, decimals = 1) {
        if (bytes === 0) return '0 Bytes';
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'Kb', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }


    // useful to copy HTML snippet with picture tags to clipboard
    function copyPictureTagsToClipboard() {
      
      window.getSelection().selectAllChildren(
        document.getElementById("pictureElementText") 
      )
      
      // let copyText = document.getElementById("pictureElementText")
      // copyText.select();
      document.execCommand("copy")
    }

    //return list of supported file formats.
    // we take jpeg for granted, but webp and avif require a test
    // function supportedFormats() {

    //   let supArray = ["jpeg"]

    //   // SO: https://stackoverflow.com/questions/5573096/detecting-webp-support
    //   let elem = document.createElement('canvas');

    //   if (!!(elem.getContext && elem.getContext('2d'))) {
    //     // was able or not to get WebP representation
    //     if (elem.toDataURL('image/webp').indexOf('data:image/webp') == 0) {
    //       supArray.push("webp")
    //     }
    //   }      

    //   return supArray
    // }
    
	return { /* secureFileName, */ downloadAsZip, downloadSingleImage, formatBytes, copyPictureTagsToClipboard }
}