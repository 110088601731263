// import { ref } from 'vue'
// import { storeToRefs } from "pinia"
import { useUiElements } from "./../store/uiElements"

export async function uploadFile(file, url) {
	
	const uiStore = useUiElements()

	// set up the request data
	let formData = new FormData()
	formData.append('file', file.file)
	formData.append('percentage', uiStore.pct)

	// track status and upload file
	file.status = 'loading'
	let response = await fetch(url, { method: 'POST', body: formData })

	// change status to indicate the success of the upload request
	file.status = response.ok
	
	return await response.json()
}

export async function uploadFiles(files, url) {
	// console.log("(uploadFiles) files:", files)
	return await Promise.all(files.map((file) => uploadFile(file, url)))
}

// some refactoring due. now that you are suing Pinia store to support the UI across the
// board, there's no need to pass these parameters explicitly
export async function downloadOptimizedFiles() {
	
	const uiStore = useUiElements()
	// const {optimizedImagesCount, optimizationInProgress} = storeToRefs(uiStore)


	return await Promise.all(uiStore.imageVersions.map((img) => {
		
		//adjust for screen percentage
		img.width = Math.floor(img.width * (uiStore.pct/100))

		// console.log(img.id +" : " + wrkImg.url)
		let url = `${uiStore.workableImage.url}?imgeng=/w_${img.width}/f_${img.format}`

		// let's remove .jpg/.gif/.png, etc ftom file name
		// https://stackoverflow.com/questions/1818310/regular-expression-to-remove-a-files-extension
		let tmpFilename = uiStore.workableImage.name
		let noExtensionFilename = tmpFilename.substr(0, tmpFilename.lastIndexOf('.')) || tmpFilename;

		let imageFilename = `${noExtensionFilename}${img.suffix}.${img.format}`

		fetch(url).then((res) => {
			uiStore.optimizedImagesCount++;
			// console.log("1. optimizedImagesCount => ", optimizedImagesCount.value)

			img.size = res.headers.get("content-length")
			res.blob().then((data) => {
				img.blob = window.URL.createObjectURL(data)

				img.imgEl.onload = () => {
					// this may seem overkill, but there are many factors that may bring the image to have a different size.
					// Better to differentiate between directives to IE and what ends up displayed on the screen
					img.naturalHeight = img.imgEl.naturalHeight
					img.naturalWidth = img.imgEl.naturalWidth
					uiStore.supportedFormats.add(img.format)

					if (uiStore.optimizedImagesCount === 6) {
						// console.log("End of optimization phase...")               
						uiStore.optimizationInProgress = false
					}
				}
			});
		})
		img.url = url
		img.filename = imageFilename
		return
		
	}))
}


export async function fetchImageFromWeb(urlFetcherEndpoint) {

	
	// alert("You want to retrieve "+urlToFetch+"   with  " + urlFetcherEndpoint + "/" + urlToFetch +"\n Ok. I get it")
	// return true
	const uiStore = useUiElements()
	console.log("urlToFetch: "+uiStore.fetchedImageUrl)
	console.log("urlFetcherEndpoint: "+urlFetcherEndpoint)

	uiStore.awaitingFetchImage = true
	// uiStore.fetchedImageUrl = urlToFetch

	// set up the request data
	let formData = new FormData()
	formData.append('urlToFetch', uiStore.fetchedImageUrl)
	formData.append('percentage', uiStore.pct)



	// let response = await fetch(urlFetcherEndpoint, { method: 'POST', body: formData })

	return await fetch(urlFetcherEndpoint, { method: 'POST', body: formData })

	/* 
	// in spite of the name json() returns a JS object and not JSON
	let responseObject = await response.json()

	console.log("responseObject.error: "+ responseObject.Error)
	if (responseObject.Error) {
		console.log(responseObject.Error)
		uiStore.awaitingFetchImage = false
		uiStore.fetchedImageError = responseObject.Error
		console.log("there was an error returning FALSE")
		return false
	}


	uiStore.workableImage.name = responseObject.name
	uiStore.workableImage.size = responseObject.size
	// uiStore.workableImage.width = justOnefileReally[0].width
	// uiStore.workableImage.height = justOnefileReally[0].height
	uiStore.workableImage.url = uiStore.s3urlRoot + responseObject.name
	uiStore.workableImage.ready = true

	// console.log("uiStore.optimizationInProgress", uiStore.optimizationInProgress)
   
	//downloadOptimizedFiles(uiStore.imageVersions, uiStore.workableImage, pct)
	downloadOptimizedFiles()
		.then(() => {
			// console.log("All images loaded")
			uiStore.showDownloadables = true
		})
	
	uiStore.awaitingFetchImage = false
	uiStore.imageFromInternetModal = false
	console.log("About to return TRUE")
	return true
   */
}

export default function createUploader(baseUrl) {

	const uploadUrlEndpoint = baseUrl + "/upload"
	
	const urlFetcherEndpoint = baseUrl + "/fetchImage"

	return {
		uploadFile: function (file) {
			return uploadFile(file, uploadUrlEndpoint)
		},
		// Luca. Added percentage parameter.
		uploadFiles: function (files) {
			return uploadFiles(files, uploadUrlEndpoint)
		},
		// Luca, added download optimized files
		downloadOptimizedFiles: function() {
			return downloadOptimizedFiles()
		},

		// Luca, fetch Image through URL
		fetchImageFromWeb: function() {
			return fetchImageFromWeb(urlFetcherEndpoint)
		},
	}
}
