import { defineStore } from "pinia";


export const useUiElements = defineStore("uiStorage", {
    state: () =>  {
        console.log("state initialized")
        return {

            files: [], // uploaded files {for the time being there'll be only one}

            // the root of all IE transformation
            s3urlRoot: "https://vd331zy7.cdn.imgeng.in/uploads/",
            // IEUrlRoot: "https://vd331zy7.cdn.imgeng.in/",

            showAddSetting : false, // show/hide additional settings
            showDownloadables : false, // show/hide additional settings
            percentageList : ["100", "66", "50", "25" ], // supported resize options
            
            optimizationInProgress: false, // useful to tell user that optimization is in progress
            optimizedImagesList : ["0", "16", "33", "50","66","84","100" ], // supported resize 
            optimizedImagesCount : 0,
            
            pct : 100,  // resize options. default = 100% of target screen
            
            // current uploaded image
            workableImage : {
            
                ready :  false, // initially we have no image to work with
                name :  "", // Image Name
                url : "" // workable image
            },

            //keep track of the picture element text
            //pictureElementText: "",

            // keep track of supported formats
            supportedFormats: new Set(["jpeg"]),


            // all previewed images in Downloadables invisible initially
            imageVersions : [
                {id:0, showImage: true, size:0, suffix: "_large", format: "jpeg", width: 1280,
                description: "Desktops, tablets, ideal for sharing through email and texting", highlight: true},
                {id:1, showImage: true, size:0, suffix: "_small", format: "jpeg", width: 360,
                description: "Older devices with smaller screen. Wristwatches", highlight: false},
                {id:2, showImage: true, size:0, suffix: "_large", format: "avif", width: 1280,
                description: "Newer tablets. High-res smartphones with modern browsers", highlight: false},
                {id:3, showImage: true, size:0, suffix: "_small", format: "avif", width: 360,
                description: "Small screens, newer browsers", highlight: false},
                {id:4, showImage: true, size:0, suffix: "_large", format: "webp", width: 1280,
                description: "Tablets. High-res smartphones and desktops", highlight: false},
                {id:5, showImage: true, size:0, suffix: "_small", format: "webp", width: 360,
                description: "Small screens. Old smartphones", highlight: false}
            ],

            // image from internet Model (control visibility)
            imageFromInternetModal: false,
            awaitingFetchImage: false,
            fetchedImageError: "",
            fetchedImageUrl: "",

            // subfolder name and alt attribure (needed to produce <picture> element)
            subfolderUser: "",
            altattr: "",

            // little optimization on smartphone. Let user click to visualize instructions
            showSmartphoneInstrux: false,

            // show/hide area for images fetched from the web
            showFetchedImagePreviewArea: false
        }
    },

    getters: {

        isEmptyWorkableImage: (state) => {
            return state.files.length === 0;
            },
        
        isEmptyPackage: (state) => {
            return state.optimizedImagesCount < 6;
            },
        
        
        optimizedImagesPercentage: (state) => {
            return state.optimizedImagesList[state.optimizedImagesCount]
            },

        isUploading: (state) => {
            
            if (state.files.length === 0) {
                return false
            }
            return state.files[0].status == 'loading'
            },

        subFolderWithSlash: (state) => {

            if (state.subfolderUser === "") {
                return ""
            }
            return state.subfolderUser+"/"

        },
 
        // calculate bandwidth of optimized image, multiply average of all versions by 1.5. 
        estimatedImageBandwidth: (state) => {
            const adjuster = 1.5 // more? less?
            // get an array of int representing the size of each optimized image
            let allSizesArray = state.imageVersions.map(a => parseInt(a.size))        
            // https://stackoverflow.com/questions/29544371/finding-the-average-of-an-array-using-js
            return adjuster * (allSizesArray.reduce((a, b) => a + b, 0) / state.imageVersions.length)
        },

        //  Comparison with size of original image
        // will yield an estimate of saved bandwidth
        estimatedSavings: (state) => {
            
                return state.workableImage.size - state.estimatedImageBandwidth
        },


        estimatedSavingsPercentage: (state) => {
        
            let savingsPercentage = (state.workableImage.size - state.estimatedImageBandwidth)/state.workableImage.size
            //console.log("savingsPercentage: "+savingsPercentage)
            return Math.floor(savingsPercentage * 100)
        },

    },

    actions: {

        isImageFormatSupported(format) {
            // console.log("Checking if browser supports " + format + "..." + this.supportedFormats.has(format))
            return this.supportedFormats.has(format)
        },

        resetImageVersionArray() {
            console.log("resetting ImageVersion Array...");
            // this.imageVersions = null  //baseImageVersionArray
            // console.log("INSIDE: this.imageVersions[0].url: "+ this.imageVersions[0].url)

            // Tried all kinds of assignment. In the end, "replaying" the full data structure was the only option
            this.imageVersions =  [
                {id:0, showImage: true, size:0, suffix: "_large", format: "jpeg", width: 1280,
                description: "Desktops, tablets, ideal for sharing through email and texting", highlight: true},
                {id:1, showImage: true, size:0, suffix: "_small", format: "jpeg", width: 360,
                description: "Older devices with smaller screen. Wristwatches", highlight: false},
                {id:2, showImage: true, size:0, suffix: "_large", format: "avif", width: 1280,
                description: "Newer tablets. High-res smartphones with modern browsers", highlight: false},
                {id:3, showImage: true, size:0, suffix: "_small", format: "avif", width: 360,
                description: "Small screens, newer browsers", highlight: false},
                {id:4, showImage: true, size:0, suffix: "_large", format: "webp", width: 1280,
                description: "Tablets. High-res smartphones and desktops", highlight: false},
                {id:5, showImage: true, size:0, suffix: "_small", format: "webp", width: 360,
                description: "Small screens. Old smartphones", highlight: false}
            ]
            
            // Object.assign(this.imageVersions, baseImageVersionArray)
            // this.imageVersions[0].url = undefined
        },

        
        toggleImageFromInternetModalModal() {
            this.awaitingFetchImage = false
            this.imageFromInternetModal = !this.imageFromInternetModal
            // hack. I need the URL input text field to have the focus. Not too elegant but
            // I couldn't find an event that triggers with v-show changes?
            if (this.imageFromInternetModal) {
                setTimeout(() => {
                    const inputElem = document.getElementById("imageUrlInputElement")
                    if (inputElem) {
                        // console.log(inputElem,"inputElem esiste. Proviamo a dargli il focus()")
                        inputElem.focus()
                    }
                }, 200)
            }
        },
  
    
    }
        
        
    //     resetImage() {
    //         console.log("resetting image...");
    //         this.currentImage = null;
    //         this.versions = Array(6);
    //         this.zippedFile = null;
    //     },
    // }
});