<template>
    <div id="app">

        <NavBar :wurflData="myWURFLData"/>

        <!-- Modal @close="toggleModal" :modalActive="modalActive" -->
        <ModalRetrieveImageFromUrl @fetch-url="prepareFetchImageFromWeb(uiStore.fetchedImageUrl)"/>

        <div id="TESTREMOVE">-</div>
        <!-- from:  smashingmagazine.com/2022/03/drag-drop-file-uploader-vuejs-3/ -->
        <!-- div class="d-flex flex-row flex-wrap justify-content-between" -->
        <div class="flex flex-wrap">

<!-- FIRST COLUMN  -->

            <div class="inline px-2 min-w-[320px] w-full md:w-1/2 bg-white text-center">

                <!-- p class="text-lg"><b>Looking to optimize and compress your high-resolution images for better web and mobile performance?</b><br/> Upload your images here and we'll resize them for you.</p -->
                <!-- h1>FormFactor: {{myWURFLData.formFactor}}</h1 -->
                <DropZone class="drop-area min-w-[240px] min-h-[140px] m-4 p-4 bg-gray-200 border-dashed border-2 border-[#4436b0] cursor-pointer transition ease-in-out delay-150 hover:bg-gray-300 duration-300 hover:shadow-lg rounded-xl hover:rounded-lg"
                    @files-dropped="addFiles" #default="{ dropZoneActive }">
                    <label for="file-input" class="dropzonelabel block text-2xl cursor-pointer">
                        <template v-if="dropZoneActive">
                            <span class="block">Drop Them Here</span>
                            <span class="text-sm block">to add them</span>
                        </template>
                        <template v-else>
                            <span v-show="!uiStore.files.length && !myWURFLData.isMobile" class="block">Drag Your Image Here</span>
                            <span v-show="!uiStore.files.length && !myWURFLData.isMobile" class="block text-sm">
                                or <strong><em>click here</em></strong> to select your image file <template v-if="myWURFLData.isDesktop">(Got an image in your clipboard? <b>CTRL/CMD + V</b> should work too!)</template>
                            </span>
                            <span v-show="!uiStore.files.length && myWURFLData.isMobile" class="block">
                                <strong><em>click here</em></strong> to select your image file. <br/><span class="text-sm">Reduce the size of "heavy" images for inclusions in emails, web pages or text messages.</span>
                            </span>
                        </template>

                        <input type="file" id="file-input" @change="onInputChange" accept="image/*"/>
                    </label>
                    <div class="w-1/2 mx-auto py-2.5 px-auto bg-gray-200 rounded" align="center" v-show="uiStore.files.length">
                        <FilePreview v-for="file in uiStore.files" :key="file.id" :file="file" tag="div" @remove="removeFile" />
                    </div>

                </DropZone>

                <!-- upload URL with image URL. On non mobile, this is an important feature.
                     Mobile deivices will access this under the additional settings -->
                <div v-show="uiStore.isEmptyWorkableImage && !myWURFLData.isMobile" class="p-4 text-right">
                    <b>Tip:</b> <i>You can also </i>
                    <a @click.prevent="uiStore.toggleImageFromInternetModalModal" href="#" class="btn btn-secondary rounded-md" role="button">fetch  image from a URL</a>
                    <br>
                </div>

                <!-- Additional Settings -->
                <div class="w-full p-4">
                    <div class="flex flex-nowrap justify-between bg-primary rounded-t-lg">
                        <div class="text-lg text-primary-content text-left px-2">
                            <a href="#" @click.prevent="uiStore.showAddSetting = !uiStore.showAddSetting" class="text-lg">
                                <span v-show="uiStore.showAddSetting">Click to hide</span>
                                <span v-show="!uiStore.showAddSetting">Click for more options</span>
                            </a>
                        </div>
                        <div class="text-lg text-primary-content text-left px-2">
                            <a href="#" @click.prevent="uiStore.showAddSetting = !uiStore.showAddSetting" class="text-lg">
                            <span v-show="uiStore.showAddSetting">-</span>
                            <span v-show="!uiStore.showAddSetting"><b>+</b></span>
                            </a>
                        </div>

                    </div>

                    <div v-show="uiStore.showAddSetting" class="flex flex-wrap justify-between  bg-gray-400 px-3 pt-3">
                        <!-- percentage of screen -->
                        <div class="text-lg">
                            <label for="percentageScreen">Further reduce image width:</label>
                        </div>
                        <div class="text-lg">
                            <select class="form-select" v-model="uiStore.pct" @change="startUploadFilesIfImageAvailable()" id="percentageScreen">
                                <option selected value="100">Please select one</option>
                                <option v-for="pctidx in uiStore.percentageList" :key="pctidx" :value="pctidx">{{pctidx}} %</option>
                            </select>
                        </div>
                    </div>

                    <div v-show="uiStore.showAddSetting" class="flex flex-wrap justify-between  bg-gray-400 px-3 pb-2  rounded-b-lg">
                        <!-- upload URL with image URL -->
                        <div v-show="uiStore.isEmptyWorkableImage && myWURFLData.isMobile" class="text-lg">
                            <label for="fetchUrlLinkButton">You can also:</label>
                        </div>
                        <div v-show="uiStore.isEmptyWorkableImage && myWURFLData.isMobile" class="pb-2 w-full text-right">
                            <!-- b>Tip:</b> <i>You can also </i -->
                            <a @click.prevent="uiStore.toggleImageFromInternetModalModal" href="#" class="btn btn-secondary rounded-md" role="button" id="fetchUrlLinkButton">fetch  image from a URL</a>
                            <br>
                        </div>

                    </div>
                </div>

                <button v-show="!uiStore.isEmptyWorkableImage" @click.prevent="startUploadFiles()" title="Upload Your Image" class="btn btn-lg btn-primary rounded-md">Upload Your Image</button>

                <!-- button v-show="uiStore.isEmptyWorkableImage" @click.prevent="clickOnFileInput()" class="btn btn-outline btn-lg btn-ghost rounded-md">Select Image</button -->


                <div class="flex flex-row-reverse w-full p-4" v-show="!myWURFLData.isMobilePhone && !uiStore.workableImage.ready && !uiStore.isUploading">
                    <div class="w-[80%] text-right inline"><b>Tip:</b>&nbsp;
                        <span class="italic">Not comfortable uploading one of your images just yet? Select one of the predefined images.</span>
                    </div>
                </div>

                <div class="w-full text-lg p-4">
                    <div class="bg-gray-300 rounded-lg"><b>Important:</b>&nbsp;
                    <span class="italic">Your images will be automatically deleted from our servers within a few hours.</span>
                    </div>
                </div>

            </div>
<!-- END OF FIRST COLUMN -->
<!-- SECOND COLUMN -->

            <div class="inline px-2 min-w-[320px] w-full md:w-1/2 bg-white">
                <!-- INITIAL INFORMATION SECOND COLUMN -->

                <div v-show="uiStore.isUploading || uiStore.awaitingFetchImage" class="card w-96 bg-base-100">
                    <div class="card-body">
                        <h2 class="card-title"><svg class="animate-spin -ml-1 mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>Uploading file... </h2>
                    </div>
                </div>


                <div v-show="!uiStore.workableImage.ready && !uiStore.isUploading" class="text-left p-4">

                    <div v-show="!myWURFLData.isMobilePhone">
                        <b>How it works:</b>
                        <ol class="list-disc list-inside p-3">
                            <li>Upload the image you'd like to optimize.</li>
                            <li>Give ImageEngine a moment to work its magic.</li>
                            <li>Download the <code>.zip</code> file containing your optimized images.</li>
                        </ol>

                        <div class="font-bold p-3">We've even added HTML tags to make it easy to take advantage of optimization across all devices.</div>
                    </div>

                    <div v-show="myWURFLData.isMobilePhone">
                        <div class="flex flex-nowrap justify-end">
                            <a class="w-[80%] text-right inline" href="#" @click.prevent="uiStore.showSmartphoneInstrux = !uiStore.showSmartphoneInstrux"><b>Tip:</b> <i>Confused about how this works? Click me!</i></a>
                        </div>
                        <ol v-show="uiStore.showSmartphoneInstrux" class="list-disc list-inside p-3">
                            <li>Upload the image you'd like to optimize.</li>
                            <li>Give ImageEngine a moment to work its magic.</li>
                            <li>View, share or download your optimized image (it'll be MUCH smaller, we promise!)</li>
                        </ol>
                    </div>

                    <div v-show="!myWURFLData.isMobilePhone" class="flex flex-wrap justify-around w-full bg-gradient-to-r from-[#FF66FF] to-[#4636B3] p-4 rounded-xl">

                        <div class="w-full font-bold pb-1 font-bold text-lg">Predefined images:</div>
                        <div class="inline w-32 bg-gray-200 px-3 py-2 rounded-md mr-1 mb-1">
                            <div class="p-0.2">
                            <a href="#" @click.prevent="setImageOnStage(1)">
                                <img :src="`${uiStore.s3urlRoot}image1.jpg?imgeng=/w_120/f_jpg`" width="120" loading="lazy" alt="" class="rounded-md"
                                     :srcset="`${uiStore.s3urlRoot}image1.jpg?imgeng=/w_240/f_jpg 2x`" />
                            </a>
                            </div>
                        </div>

                        <div class="inline w-32 bg-gray-200 px-3 py-2 rounded-md mr-1 mb-1">
                            <div class="p-0.2">
                            <a href="#" @click.prevent="setImageOnStage(2)">
                                <img :src="`${uiStore.s3urlRoot}image2.jpg?imgeng=/w_120/f_jpg`" width="120" loading="lazy" alt="" class="rounded-md"
                                     :srcset="`${uiStore.s3urlRoot}image2.jpg?imgeng=/w_240/f_jpg 2x`" />
                            </a>
                            </div>
                        </div>


                        <div class="inline w-32 bg-gray-200 px-3 py-2 rounded-md mr-1 mb-1">
                            <div class="p-0.2">
                            <a href="#" @click.prevent="setImageOnStage(3)">
                                <img :src="`${uiStore.s3urlRoot}image3.jpg?imgeng=/w_120/f_jpg`" width="120" loading="lazy" alt="" class="rounded-md"
                                     :srcset="`${uiStore.s3urlRoot}image3.jpg?imgeng=/w_240/f_jpg 2x`" />
                            </a>
                            </div>
                        </div>


                        <div class="inline w-32 bg-gray-200 px-3 py-2 rounded-md mr-1 mb-1">
                            <div class="p-0.2">
                            <a href="#" @click.prevent="setImageOnStage(4)">
                                <img :src="`${uiStore.s3urlRoot}image4.jpg?imgeng=/w_120/f_jpg`" width="120" loading="lazy" alt="" class="rounded-md"
                                     :srcset="`${uiStore.s3urlRoot}image4.jpg?imgeng=/w_240/f_jpg 2x`" />
                            </a>
                            </div>
                        </div>

                        <div class="inline w-32 bg-gray-200 px-3 py-2 rounded-md mr-1 mb-1">
                            <div class="p-0.2">
                            <a href="#" @click.prevent="setImageOnStage(5)">
                                <img :src="`${uiStore.s3urlRoot}image5.jpg?imgeng=/w_120/f_jpg`" width="120" loading="lazy" alt="" class="rounded-md"
                                     :srcset="`${uiStore.s3urlRoot}image5.jpg?imgeng=/w_240/f_jpg 2x`" />
                            </a>
                            </div>
                        </div>


                        <div class="inline w-32 bg-gray-200 px-3 py-2 rounded-md mr-1 mb-1">
                            <div class="p-0.2">
                            <a href="#" @click.prevent="setImageOnStage(6)">
                                <img :src="`${uiStore.s3urlRoot}image6.jpg?imgeng=/w_120/f_jpg`" width="120" loading="lazy" alt="" class="rounded-md"
                                     :srcset="`${uiStore.s3urlRoot}image6.jpg?imgeng=/w_240/f_jpg 2x`" />
                            </a>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- h4 v-show="!uiStore.workableImage.ready && !uiStore.isUploading">[VIDEO BELOW TO BE REPLACED WTH VIDEO THAT ILLUSTRATES ONLINE IMAGE OPTIMIZER]</h4 -->
                <!-- iframe v-show="!uiStore.workableImage.ready && !uiStore.isUploading" width="90%" src="https://www.youtube.com/embed/Vt9f7--VOk8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe -->

                <!-- END OF INITIAL INFORMATION SECOND COLUMN  -->


                <div v-show="uiStore.workableImage.ready" class="min-w-[320px] w-full bg-base-100 shadow-x clear-both">
                    <div v-show="uiStore.showFetchedImagePreviewArea" class="bg-gradient-to-r from-[#FF66FF] to-[#4636B3] p-4 rounded-xl flex items-center justify-center">
                        <div class="px-4" id="fetchedUrlPreview">
                        </div>
                    </div>
                    <div class="p-4">
                        <div class="flex flex-wrap justify-between">
                            <div class="inline">
                                <span class="text-lg">Original image: <br/><b>{{uiStore.workableImage.name}}</b></span>
                                <p v-show="!uiStore.isUploading">{{formatBytes(uiStore.workableImage.size)}},
                                    {{uiStore.workableImage.width}} x {{uiStore.workableImage.height}} pixels</p>
                                <div v-show="uiStore.isUploading" class="spinner-border text-success" role="status">Uploading image...
                                </div>
                            </div>

                            <div class="inline">
                                <div v-show="uiStore.optimizationInProgress" class="text-center px-4 py-2">
                                    <div class="radial-progress text-primary" :style="`--value:${uiStore.optimizedImagesPercentage};`">{{uiStore.optimizedImagesPercentage}}%</div>
                                </div>
                                <div v-show="!uiStore.optimizationInProgress && uiStore.workableImage.ready" class="text-center px-4">
                                    <div class="radial-progress text-primary" :style="`--value:${uiStore.optimizedImagesPercentage};`">{{uiStore.optimizedImagesPercentage}}%</div>
                                </div>
                            </div>
                        </div>

                        <div v-show="!uiStore.optimizationInProgress && uiStore.workableImage.ready && !myWURFLData.isMobilePhone" class="px-4">
                            <b>Optimization complete</b>. Press the button below to download all optimized files <span v-show="myWURFLData.isMobile">to your mobile device</span> or
                            [<a class="link link-primary" href="#downloadables">preview/download a single specific image <span v-show="myWURFLData.isMobile">ready for sharing</span></a>] <br/>
                            <i>hint: don't wait. Your files won't stick around our servers for much longer</i>
                        </div>

                        <div v-show="!myWURFLData.isMobilePhone" class="text-center py-2" >
                            <button @click.prevent="downloadAsZip()" title="Download YOUR Optimized Images and HTML Tags" class="btn btn-primary rounded" :disabled="uiStore.isEmptyPackage">Download your files</button>
                        </div>
                        <!-- the Pictired element thing only makes sense on fully fledged desktops really -->
                        <div v-show="!uiStore.optimizationInProgress && uiStore.workableImage.ready  && !myWURFLData.isMobilePhone">
                            <div class="text-left text-sm bg-gray-300 p-4 overflow-x-auto">
                                <pre id="pictureElementText">
&lt;picture&gt;
 &lt;source type="image/{{uiStore.imageVersions[2].format}}" sizes="{{uiStore.pct}}vw"
    srcset="{{uiStore.subFolderWithSlash}}{{uiStore.imageVersions[2].filename}} {{uiStore.imageVersions[2].naturalWidth}}w,&#8203; {{uiStore.subFolderWithSlash}}{{uiStore.imageVersions[3].filename}} {{uiStore.imageVersions[3].naturalWidth}}w"&gt;
 &lt;source type="image/{{uiStore.imageVersions[4].format}}" sizes="{{uiStore.pct}}vw"
    srcset="{{uiStore.subFolderWithSlash}}{{uiStore.imageVersions[4].filename}} {{uiStore.imageVersions[4].naturalWidth}}w,&#8203; {{uiStore.subFolderWithSlash}}{{uiStore.imageVersions[5].filename}} {{uiStore.imageVersions[5].naturalWidth}}w"&gt;
 &lt;img alt="{{uiStore.altattr}}" src="{{uiStore.subFolderWithSlash}}{{uiStore.imageVersions[0].filename}}"
    srcset="{{uiStore.subFolderWithSlash}}{{uiStore.imageVersions[0].filename}} {{uiStore.imageVersions[0].naturalWidth}}w,&#8203; {{uiStore.subFolderWithSlash}}{{uiStore.imageVersions[1].filename}} {{uiStore.imageVersions[1].naturalWidth}}w"
    sizes="{{uiStore.pct}}vw" loading="lazy" decoding="async"&gt;
&lt;/picture&gt;
                                </pre>
                            </div>

                            <div class="flex flex-wrap justify-between h-full bg-gray-300 p-2">
                                <div class="text-lg grow-1 basis-2/5 p-1">
                                    <input type="text" v-model="uiStore.subfolderUser" class="input w-full max-w-xs rounded" placeholder="For ex: image_folder" />
                                </div>

                                <div class="text-lg grow-1 basis-2/5 p-1">
                                    <input type="text" v-model="uiStore.altattr" class="input w-full max-w-xs rounded" id="exampleFormControlInput2" placeholder="'alt' attribute" />
                                </div>

                                <div class="text-lg sm:grow-[5] basis-1/5 p-1">
                                    <a @click.prevent="copyPictureTagsToClipboard()" title="Copy PICTURE Element to clipboard" href="#" class="btn btn-secondary rounded" role="button"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-clipboard" viewBox="0 0 16 16">
    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
    </svg></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

    <!-- END OF SECOND COLUMN -->
        </div>
    </div>


<!-- TABLE OF OPTIMIZED VERSIONS -->
    <!-- div id="tableOfDownloadableImages" class="wf"> </div -->
    <div id="downloadables" class="px-4 w-full" v-show="uiStore.workableImage.ready && uiStore.showDownloadables">

        <div class="flex flex-wrap w-full justify-between">
            <div class="px-4"><span class="badge badge-success h-10 text-xl"><b>Estimated Savings:</b>  {{uiStore.estimatedSavingsPercentage}}%</span></div>
            <div class="px-4">
            <span class="text-lg"><b>Tip:</b> Want image optimization to happen automatically on all images on your website? Check out <a class="link link-primary" href="https://imageengine.io" target="_blank">ImageEngine.io</a></span></div>
        </div>

        <table class="table table-zebra w-full">
            <thead>
                <tr>
                <!-- th scope="col">&nbsp;</th -->
                <th scope="col" class="hidden md:table-cell text-sm">Width</th>
                <th scope="col" class="hidden md:table-cell text-sm">Height</th>
                <th scope="col" class="hidden sm:table-cell text-sm">Format</th>
                <th scope="col" class="text-sm">File Size</th>
                <th scope="col" class="hidden lg:table-cell text-sm">Target Devices</th>
                <th scope="col" class="text-sm">Downloadables</th>
                </tr>
            </thead>
            <tbody>
            <!-- why the index? https://stackoverflow.com/questions/37354482/vue-js-even-numbered-rows-formatting -->
            <template v-for="(imageData,index) in uiStore.imageVersions" :key="imageData">
             <!-- in the case of mobile phones, we only show the first two optimized images -->
             <tr v-show="index < 2 || (index >= 2 && !myWURFLData.isMobilePhone)"> <!--  v-if="!imageData.highlight" -->
                <td class="hidden md:table-cell text-sm">{{imageData.naturalWidth}}</td>
                <td class="hidden md:table-cell text-sm">{{imageData.naturalHeight}}</td>
                <td class="hidden sm:table-cell text-sm">{{imageData.format}}</td>
                <td class="text-sm">{{formatBytes(imageData.size)}}</td>
                <td class="hidden lg:table-cell text-sm">{{imageData.description}}</td>
                <td class="text-sm"><a v-show="!myWURFLData.isMobilePhone" @click.prevent="toggleImg(imageData.id)" href="#" class="btn btn-secondary btn-sm rounded" role="button"><span v-show="!imageData.showImage">View Image</span><span v-show="imageData.showImage">Hide Image</span></a> <a href="#" class="btn btn-tertiary btn-sm rounded" role="button"  @click.prevent="downloadSingleImage(imageData.url, imageData.filename)">Download</a></td>
             </tr>

             <!-- in the case of mobile phones, we only show the first two optimized images -->
             <tr v-show="imageData.showImage && (index < 2 || (index >= 2 && !myWURFLData.isMobilePhone))">
                <td :id="`imgcell${imageData.id}`" colspan="6" align="center">
                    <!-- id="`img${imageData.id}`" -->
                    <div v-if="!uiStore.isImageFormatSupported(imageData.format)">Your browser does not support <code>.{{imageData.format}} </code> unfortunately, but you can still download the image file.</div>
                    <img v-show="uiStore.isImageFormatSupported(imageData.format)" :ref="(el) => { imageData.imgEl = el }" :class="{'max-w-[50%]': index % 2 === 0, 'max-w-[25%]': index % 2 !== 0 }" :src="imageData.blob"/>
                    <div class="font-bold" v-show="myWURFLData.isMobile">Press and hold image to share.</div>
                    <div class="font-bold" v-show="!myWURFLData.isMobile">Right-click image to copy to clipboard.</div>
                    <div class="text-sm">Original: {{formatBytes(uiStore.workableImage.size)}},
                                    {{uiStore.workableImage.width}} x {{uiStore.workableImage.height}}<br/>
                    Optimized: {{formatBytes(imageData.size)}}, {{imageData.naturalWidth}} x {{imageData.naturalHeight}}</div>
                </td>
             </tr>
            </template>
            </tbody>
        </table>
    </div>

    <!-- FOOTER -->
    <AppFooter  :wurflData="myWURFLData" />
</template>

<script setup>
import {useUiElements} from "./store/uiElements"
import { ref, onMounted, onUnmounted, inject } from "vue"

// Components
// import MyModal from "./components/MyModal.vue"
import ModalRetrieveImageFromUrl from "./components/ModalRetrieveImageFromUrl.vue"
import NavBar from './components/NavBar.vue'
import DropZone from './components/DropZone.vue'
import FilePreview from './components/FilePreview.vue'
import AppFooter from './components/AppFooter.vue'

import useSomeUtils from './compositions/utils'

//File Utils
import useFileList from './compositions/file-list'
// Uploader
import createUploader from './compositions/file-uploader'
// import { createObjectExpression } from "@vue/compiler-core"
// import { createSimpleExpression } from "@vue/compiler-core"


// Debugging. Timing of when and in which orders certain events trigger
// https://stackoverflow.com/questions/63100658/add-global-variable-in-vue-js-3
// const start = inject('start')
const start = window.start //new Date().getTime()
//console.log(start, "Start time in APP.vue")
// console.log(new Date().getTime() - start,"reading script section of App.vue")

// let's add WURFL.js support programmatically
// console.log(new Date().getTime() - start, "loading WURFL.js from APP.vue component")
const wurfljs = document.createElement("script")
wurfljs.setAttribute(
      "src",
      "//wjs.wurflcloud.com/wurfl.js"
    )
document.head.appendChild(wurfljs);

// ScientiaMobile is the Device Detection company. Let's include WURFL.
// formFactor is one of {Desktop, Tablet, Smartphone, Feature Phone, Smart-TV, Robot, Other non-Mobile, Other Mobile}
const myWURFLData = ref({})
// shall we add some quick and dirty form factor detection in the unlikely event WURFL.js
// is unavailable? mmmm.... decisions, decisions. This should capture well north of 95% traffic
myWURFLData.value.formFactor = ""
myWURFLData.value.completeDeviceName = "Undefined"

const userAgent = navigator.userAgent.toLowerCase()
myWURFLData.value.isMobilePhone = /(ipod|phone|(android(.*)mobile))/.test(userAgent)
myWURFLData.value.isTablet = /(ipad|tablet|(android(?!.*mobile))|kindle|playbook|silk)/.test(userAgent)
myWURFLData.value.isSmartTV = /(googletv|sonydtv|appletv|apple tv|smarttv|smart-tv|dlna|inettvbrowser|opera tv|sony bravia|sonycebrowser|hbbtv|large screen|netcast|philipstv|digital-tv|vizio|bravia|roku|vudu|sony bd\/|sony dtv\/|tivo-device|vizio, d|tvos|samsung-agent\/|samsunghas-agent\/|model\/hisense|virginmedia_stb|firetv|android tv; ap|amazon_tv; ap|android tv\/|fire tv\/)/.test(userAgent)
myWURFLData.value.isiOS = /(ipod|ipad|iphone|)/.test(userAgent)
myWURFLData.value.isAndroid = /android/.test(userAgent)

myWURFLData.value.isMobile = myWURFLData.value.isTablet || myWURFLData.value.isMobilePhone
myWURFLData.value.isDesktop = !myWURFLData.value.isMobile
if (myWURFLData.value.isMobilePhone) myWURFLData.value.formFactor = "Mobile Phone"
if (myWURFLData.value.isTablet) myWURFLData.value.formFactor = "Tablet"
if (myWURFLData.value.isDesktop) myWURFLData.value.formFactor = "Desktop"

// we use Pinia for state. Too many interacions among components to have each manage its data
const uiStore = useUiElements()


const { /* files, */ addFiles, removeFile, removeAllChildNodes } = useFileList()
const { /* secureFileName, */ downloadAsZip, downloadSingleImage, formatBytes, copyPictureTagsToClipboard } = useSomeUtils()

// let's generalize 'http://localhost:8080/upload'
const baseUrl = window.location.protocol +"//"+ window.location.host
const { uploadFiles,downloadOptimizedFiles, fetchImageFromWeb } = createUploader(baseUrl)


// useful for testing: no need to paste URL to be fetched each time
// uiStore.fetchedImageUrl = 'https://www.passani.it/fame/luca_james.jpg'

onMounted(() => {
    // console.log(new Date().getTime() - start, "APP.Vue has mounted")

    // let's allow copying and pasting an image.
    // https://www.techiedelight.com/paste-image-from-clipboard-using-javascript/
    // https://developer.mozilla.org/en-US/docs/Web/API/DataTransferItem/getAsFile
    const fileInput = document.getElementById("file-input")

    // fill the myWURFLData object with data from WURFL.js
    window.addEventListener('WurflJSDetectionComplete', () => {
        // console.log(new Date().getTime() - start, "WurflJSDetectionComplete TRIGGERED (from App.vue)")
        myWURFLData.value.formFactor = window.WURFL.form_factor
        myWURFLData.value.isMobile = window.WURFL.is_mobile
        myWURFLData.value.completeDeviceName = window.WURFL.complete_device_name
        myWURFLData.value.isiOS = window.WURFL.is_ios
        myWURFLData.value.isAndroid = window.WURFL.is_android

        myWURFLData.value.isDesktop = myWURFLData.value.formFactor === "Desktop"
        myWURFLData.value.isMobilePhone = ["Smartphone", "Feature Phone","Other Mobile"].indexOf(myWURFLData.value.formFactor) >= 0
        myWURFLData.value.isTablet = myWURFLData.value.formFactor === "Tablet"
        myWURFLData.value.isSmartTV = myWURFLData.value.formFactor === "Smart-TV"
        // console.dir(myWURFLData)
    })


    window.addEventListener('paste', pasteEvent => {

        // there was a gotcha. This guy really helped me:
        // https://stackoverflow.com/questions/74452863/chrome-images-reading-clipboard-frm-javascript-something-must-have-changed-re/74453230#74453230
        let item;
        for (const x of pasteEvent.clipboardData.items) {
            if(x.type.indexOf("image") === 0){
                item = x;
                break;
            }
        }
        if (item) {

            console.log("%o", item)

            let clipboadFile = item.getAsFile()
            // Setting the filename of the blob in the clipboard wasn't straightforward
            // https://stackoverflow.com/questions/21720390/how-to-change-name-of-file-in-javascript-from-input-file
            let  blob = clipboadFile.slice(0, clipboadFile.size, item.type)
            let newFile = new File([blob], 'clipboardPaste_'+Date.now(), {type: item.type})
            addFiles([newFile])

            pasteEvent.preventDefault();
        }
        // } else {
        //     alert("You tried to Copy&Paste something, but... it doesn't seem to be an image!")
        // }

    })

    navigator.serviceWorker.addEventListener('message', event => {
        const mediaFiles = event.data.mediaFiles;

        // Do something with the mediaFiles here

        if (mediaFiles) {

            const fileObjects = [];
            const blob = mediaFiles[0]; // Assuming mediaFiles is an array of File objects
            const mimeType = blob.type; // This is the MIME type

            const fileObj = new File([blob], "sharedImage_" + Date.now(), { type: mimeType });

            fileObjects.push(fileObj);

            uploadFiles(fileObjects).then((thingy) => {

                // console.log(thingy[0].filename)
                // let secureFileName = thingy[0].filename
                let secureFileName = 'sharedImage_'+Date.now()
                uiStore.workableImage.ready = true;
                // rolling my own version of secureFileName as found on Python was giving me all
                // kinds of headache. Ended up letting the server tell me what file name it picked
                // uiStore.workableImage.name = secureFileName(mediaFiles[0].name);

                uiStore.workableImage.name = secureFileName
                uiStore.workableImage.size = mediaFiles[0].size
                uiStore.workableImage.width = mediaFiles[0].width
                uiStore.workableImage.height = mediaFiles[0].height
                uiStore.workableImage.url = uiStore.s3urlRoot + uiStore.workableImage.name

                uiStore.optimizationInProgress = true
                // console.log("uiStore.optimizationInProgress", uiStore.optimizationInProgress)

                downloadOptimizedFiles()
                    .then(() => {
                        // console.log("All images loaded")
                        uiStore.showDownloadables = true
                        // UX on mobile wasn't smooth, as optimized images appeared below the fold.
                        // Let's help the user by scrolling there
                        // console.log(myWURFLData.isMobile,"ready to test")
                        if (myWURFLData.value.isMobile) {
                            // I tried all possible things, but there's the funky Vue.js VDOM interferes with scrollIntoView()
                            // I settled for something more "old school" :)
                            setTimeout(() => {
                                window.location.href = "#downloadables"
                            }, 500);

                        }
                    })

            }).catch(
                (err) => alert(err)
                )
        }

    });


    // console.log("window.innerWidth: " + window.innerWidth)
    // console.log("window.innerHeight: " + window.innerHeight)
})


function onInputChange(e) {
	// console.log("1. New Files")
    // console.dir(e.target.files)
    addFiles(e.target.files)
    e.target.value = null // reset so that selecting the same file again will still cause it to fire this change
}

// toggle single image view in the Downloadables table
function toggleImg(idx) {
    uiStore.imageVersions[idx].showImage = !uiStore.imageVersions[idx].showImage;
}

// when there's still no image, people will click on the upload button. Rather than activating, we
// trigger the file selection mechanism automatically
function clickOnFileInput() {

    let fileInput = document.getElementById("file-input")
    fileInput.click()

}

// there's a funny edge case. Images has already been uploaded, but user tinkers with advanced settings
// If image has already been uploaded, trigger a new rendering, otherwise do nothing
function startUploadFilesIfImageAvailable() {

    if (uiStore.workableImage.ready) {

        // the following should be the standard way, but for some reason it doesn't work
        //uiStore.imageVersions.$reset(['imageVersionArray'])
        uiStore.resetImageVersionArray()
        uiStore.optimizationInProgress = true
        uiStore.showDownloadables = false
        uiStore.optimizedImagesCount = 0
        downloadOptimizedFiles()
            .then(() => {
                uiStore.showDownloadables = true
                // I tried all possible things, but there's the funky Vue.js VDOM interferes with scrollIntoView()
                // I settled for something more old school :)
                if (myWURFLData.value.isMobile) {
                    setTimeout(() => {
                        // console.log("SetTimeout triggered")
                        window.location.href = "#downloadables"
                    }, 500);
                }
            })
    }
}


async function startUploadFiles() {

    //console.log("#files: " + files.length)
    if (uiStore.files.length == 0) {
        alert("Please pick a picture to optimize.")
        return
    }

    uiStore.showDownloadables = false  // user uploaded new file. remove UI that refers to old downloads
    uiStore.optimizedImagesCount = 0

    // original library supported multiple uploads, but we only want one file really
    let justOnefileReally = [...uiStore.files].slice(0,1);
    uploadFiles(justOnefileReally).then((thingy) => {

        // console.log(thingy[0].filename)
        let secureFileName = thingy[0].filename

        uiStore.workableImage.ready = true;
        // rolling my own version of secureFileName as found on Python was giving me all
        // kinds of headache. Ended up letting the server tell me what file name it picked
        // iStore.workableImage.name = secureFileName(justOnefileReally[0].name);

        uiStore.workableImage.name = secureFileName
        uiStore.workableImage.size = justOnefileReally[0].size
        uiStore.workableImage.width = justOnefileReally[0].width
        uiStore.workableImage.height = justOnefileReally[0].height
        uiStore.workableImage.url = uiStore.s3urlRoot + uiStore.workableImage.name

        uiStore.optimizationInProgress = true
        // console.log("uiStore.optimizationInProgress", uiStore.optimizationInProgress)

        downloadOptimizedFiles()
            .then(() => {
                // console.log("All images loaded")
                uiStore.showDownloadables = true
                // UX on mobile wasn't smooth, as optimized images appeared below the fold.
                // Let's help the user by scrolling there
                // console.log(myWURFLData.isMobile,"ready to test")
                if (myWURFLData.value.isMobile) {
                    // I tried all possible things, but there's the funky Vue.js VDOM interferes with scrollIntoView()
                    // I settled for something more "old school" :)
                    setTimeout(() => {
                        window.location.href = "#downloadables"
                    }, 500);

                    // const downloadablesElement = document.getElementById('tableOfDownloadableImages')
                    // // const downloadablesElement = document.querySelector("tableOfDownloadableImages")
                    // // console.log(myWURFLData.isMobile,downloadablesElement, "Ok, it was mobile. About to scroll")
                    // console.log(downloadablesElement.isConnected, downloadablesElement.getBoundingClientRect())
                    // // downloadablesElement.scrollIntoView({block: "center", behavior: "smooth"})
                    // // console.log(myWURFLData.isMobile, downloadablesElement, "Ok, it was mobile. Did I scroll?")

                    // const y = downloadablesElement.getBoundingClientRect().top + window.scrollY;
                    // window.scroll({
                    //     top: y,
                    //     behavior: 'smooth'
                    // });
                    // console.log(y, "Ok, it was mobile. Did I scroll?")
                }
            })

    }).catch(
        (err) => alert(err)
        )


    // let's calculate the directives for each image and load images client-side

}


async function setImageOnStage(imgIdx) {

    const imageUrl = `${uiStore.s3urlRoot}image${imgIdx}.jpg?imgeng=/pass_true`

    fetch(imageUrl)
    .then((res) => {
        // console.log("response:")
        // console.log("%o", res)

        return res.blob()
    })
    .then((data) => {

        // console.log(data)
        const myFile = new File([data], 'image'+imgIdx+".jpg", { type: "image/jpeg", lastModified:new Date().getTime()})
        // console.log(myFile)

        const container = new DataTransfer()
        container.items.add(myFile)
        addFiles(container.files)
    })
    .catch(error => {
        return error
    })
}


// handle case Image Retrieved from URL
async function prepareFetchImageFromWeb() {


	uiStore.awaitingFetchImage = true
	uiStore.imageFromInternetModal = true

    // console.log(uiStore.fetchedImageUrl)
    // let's do some cleaning first
    const previewer = document.getElementById("fetchedUrlPreview")
    removeAllChildNodes(previewer)

    // retrieve URL and handle error conditions
    const fetchedImageResponse = await fetchImageFromWeb()
    // console.dir(fetchedImageResponse)
    const responseObject = await fetchedImageResponse.json()
    // console.dir(responseObject)

	if (responseObject.Error) {
		// console.dir(responseObject)
		uiStore.awaitingFetchImage = false
		uiStore.fetchedImageError = responseObject.Error

	} else { // we have an image

        uiStore.workableImage.name = responseObject.name
        uiStore.workableImage.size = responseObject.size
        // uiStore.workableImage.width = justOnefileReally[0].width
        // uiStore.workableImage.height = justOnefileReally[0].height
        uiStore.workableImage.url = uiStore.s3urlRoot + responseObject.name
        uiStore.workableImage.ready = true
        uiStore.optimizationInProgress = true

        let myPreviewIng = new Image()
        myPreviewIng.width = 200
        myPreviewIng.height = 140

        myPreviewIng.src = uiStore.fetchedImageUrl
        myPreviewIng.onload =  () => {

            uiStore.workableImage.width = myPreviewIng.naturalWidth
            uiStore.workableImage.height = myPreviewIng.naturalHeight
            previewer.appendChild(myPreviewIng)
            uiStore.showFetchedImagePreviewArea = true
        }

        downloadOptimizedFiles()
        .then(() => {
            // console.log("All images loaded")
            uiStore.showDownloadables = true
            //if (myWURFLData.isMobile || !myWURFLData.isMobile) {
            // I tried all possible things, but there's the funky Vue.js VDOM interferes with scrollIntoView()
            // I settled for something more old school :)
            if (myWURFLData.value.isMobile) {
                setTimeout(() => {
                    // console.log("SetTimeout triggered")
                    window.location.href = "#downloadables"
                }, 500);
            }
        })

        // uiStore.$reset()
        uiStore.awaitingFetchImage = false
        uiStore.imageFromInternetModal = false
    }
}

</script>

<style scoped>
#app {

    /* same fonts as imageengine.io
    https://www.cdnfonts.com/euclid-circular-a.font
    */
    /* font-family: 'Euclid Circular A', sans-serif; */
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* text-align: center; */
    color: #2c3e50;
    margin: 0 auto;
    height: 100%;
}


.drop-area {

    background: #ffffff55;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.drop-area[data-active=true] {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    background: #ffffffcc;
}

/* label.dropzonelabel {
    font-size: 24px;
    cursor: pointer;
    display: block;
} */

/* label.dropzonelabel span {
        display: block;
    } */

/* I would like to replace this with tailwind CSS, but it's a CSS hack to make the
   input "file-upload" thingy invisible. Leaving it here for now */
label.dropzonelabel input[type=file]:not(:focus-visible) {
        position: absolute !important;
        width: 1px !important;
        height: 1px !important;
        padding: 0 !important;
        margin: -1px !important;
        overflow: hidden !important;
        clip: rect(0, 0, 0, 0) !important;
        white-space: nowrap !important;
        border: 0 !important;
    }

.image-list {
    display: inline-block;
    list-style: none;
    flex-wrap: wrap;
    padding: 0;
}

.picture-element {
    max-height: 200px;
    overflow:auto;
    text-align: left;
    font-size: 80%;
    font-family:'Courier New', Courier, monospace;
    white-space: pre-wrap;
    word-break: keep-all;
}

.wf {
  outline: 1px solid black;
}
</style>
