<template>
	<component :is="tag" class="file-preview">
		<button @click="$emit('remove', file)" class="close-icon">&times;</button>
		<img :src="file.url" :alt="file.file.name" :title="file.file.name" ref="uplImg" @load="onImgLoad(file)"/>

		<span class="status-indicator loading-indicator" v-show="file.status == 'loading'">In Progress</span>
		<span class="status-indicator success-indicator" v-show="file.status == true">Uploaded</span>
		<span class="status-indicator failure-indicator" v-show="file.status == false">Error</span>
	</component>
</template>

<script setup>
import {ref /*, onMounted */  } from 'vue'
const uplImg = ref(null)

defineProps({
	file: { type: Object, required: true },
	tag: { type: String, default: 'li' },
})

defineEmits(['remove'])

// onMounted(() => {
//   console.log("Hi I'm just done mounting!")
// })


const onImgLoad = (file) => {
	
	// console.log("%o", uplImg.value.naturalHeight)

	file.height = uplImg.value.naturalHeight
	file.width = uplImg.value.naturalWidth
}
</script>

<style scoped>
.file-preview {
	width: 80%;
	max-width: 200px;
	margin: 1rem 2.5%;
	position: relative;
	aspect-ratio: 1/1;
	overflow: hidden;
	text-align: center;
}

.file-preview img {
		width: 100%;
		max-width: 200px;
		height: 100%;
		display: block;
		object-fit: cover;
}

.file-preview .close-icon, .status-indicator {
		position: absolute;
		line-height: 20px;
		height: 20px;
		border-radius: 20px;
		/* box-shadow: 0 0 5px currentColor; */
		right: 0.25rem;
		appearance: none;
		border: 0;
		padding: 0;
}

.file-preview .close-icon {
		width: 20px;
		font-size: 20px;
		background: #933;
		color: #fff;
		top: 0.25rem;
		cursor: pointer;
}

.file-preview .status-indicator {
		font-size: 15px;
		bottom: 0.25rem;
		padding: 0 10px;
}

.file-preview .loading-indicator {
		animation: pulse 1.5s linear 0s infinite;
		color: #000;
}

.file-preview .success-indicator {
		background: #6c6;
		color: #040;
}

.file-preview .failure-indicator {
		background: #933;
		color: #fff;
}

@keyframes pulse {
	0% {
		background: #fff;
	}

	50% {
		background: #ddd;
	}

	100% {
		background: #fff;
	}
}
</style>
