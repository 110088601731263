// import "bootstrap/dist/css/bootstrap.css";

import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from "pinia";
const app = createApp(App);

// Pinia is used for shared storage 
app.use(createPinia());
app.mount('body');


// remove soon
// createApp(App).mount('body')

// import "bootstrap/dist/js/bootstrap.js"
import "@/assets/styles/main.css";

import './assets/tailwind.css'